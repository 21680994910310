import { reactive, computed } from "vue";

export default function() {
  const state = reactive({
    currentUser: JSON.parse(localStorage.getItem('user')),
    isAuthorized: computed(isAdminOrModerator),
  });

  function isAdminOrModerator() {
    if (!state?.currentUser) { return false; }

    return state.currentUser.roles.find((el) => el == 'admin' || el == 'moderator')
  }

  return {
    currentUser: state.currentUser,
    isAuthorized: state.isAuthorized,
  };
}
